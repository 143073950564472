import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import jsforce from 'jsforce'
import SfApi from '../components/SfApi'
import { Car, School, Domain, Briefcase, Laptop, OpenInNew, CalendarMonthOutline, Bus, HomeCityOutline, MapMarker } from 'mdi-material-ui'
import Typography from "@material-ui/core/Typography";
import { CircularProgress, makeStyles } from "@material-ui/core";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { AuthenticatedNav } from '../components/AuthenticatedNav';
import { Salaries } from '../components/Salaries';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import { FilterContext } from '../../contexts/FilterContext';
import ReactGA from 'react-ga4';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    marginBottom: '30px',
    maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
  link: {
    textDecoration: 'none'
  },
  svgIcon: {
    color: '#68A4C1'
  },
  spanText: {
    verticalAlign: 'top',
    paddingLeft: '15px'
  },
  summaryPercentageSpan: {
    verticalAlign: 'top',
    paddingLeft: '8px'
  },
  tileItem: {
    padding: '15px 0',
    fontSize: '18px'
  },
  topSection: {
    paddingBottom: '25px'
  },
  program: {
    marginTop: '88px',
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2),
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  attributeList: {
    listStyleType: 'none',
    padding: '0',
  },
  button: {
    margin: theme.spacing(1),
    float: 'right'
  },
  expansionPanelsContainer: {
    clear: 'both'
  },
  expansionPanelSummary: {
    background: 'aliceblue'
  },
  salaries: {
    marginTop: '15px',
    paddingLeft: '20px'
  }
}));

export function ProgramContainer(props) {
  const classes = useStyles();
  const filterContext = useContext(FilterContext)
  const [connConfig, setConnConfig] = useState()
  const [program, setProgram] = useState()
  const [iped, setIped] = useState()
  const [programId, setProgramId] = useState(props.match.params.id)
  const [tokenRefreshed, setTokenRefreshed] = useState(false)
  const [expandAll, setExpandAll] = useState(false)
  const [expandAllProgramDetails, setExpandAllProgramDetails] = useState(false)
  const [expandAllAdmissions, setExpandAllAdmissions] = useState(false)
  const [expandAllCost, setExpandAllCost] = useState(false)
  const [expandAllServices, setExpandAllServices] = useState(false)
  const [expandAllProfile, setExpandAllProfile] = useState(false)
  const [expandAllEmployment, setExpandAllEmployment] = useState(false)
  const [expandAllAdditionalInfo, setExpandAllAdditionalInfo] = useState(false)
  const [expandAllContactInfo, setExpandAllContactInfo] = useState(false)

  useEffect(() => {
    SfApi.fetchToken().then((config) => {
      setConnConfig(config)
      makeSfRequest(new jsforce.Connection(config))
    })
  }, []) // this will only run once

  useEffect(() => {
    if (program && program.AWLEVEL__c && program.CIP_Code__c && program.IPEDS_UnitID__c) {
      SfApi.getIpeds({ unitid: program.IPEDS_UnitID__c, cipco: program.CIP_Code__c, awlevel: program.AWLEVEL__c }).then(({ iped }) => {
        setIped(iped);
      })
    }
  }, [program]);

  useEffect(() => {
    if (program) {
      SfApi.getProgram({ remoteId: programId, unitId: program.IPEDS_UnitID__c}).then(({ program: p }) => {
        program.salaries = p.salaries
        program.cost = p.cost
        program.aid = p.aid
      })
    }
  }, [program]);

  function makeSfRequest(conn) {
    var callback = (err, record) => {
      if(err) {
        !tokenRefreshed && SfApi.fetchToken().then((config) => {
          setConnConfig(config)
          setTokenRefreshed(true)
        })
      }
      setProgram(record[0])
    }

    SfApi.getPostSecondaryProgram(conn, programId, callback)
  }


  function handleExpandAll() {
    const prev = expandAll
    setExpandAllProgramDetails(!prev)
    setExpandAllAdmissions(!prev)
    setExpandAllCost(!prev)
    setExpandAllServices(!prev)
    setExpandAllProfile(!prev)
    setExpandAllEmployment(!prev)
    setExpandAllAdditionalInfo(!prev)
    setExpandAllContactInfo(!prev)
    setExpandAll(!prev)
  }

  function handlePrint() {
    ReactGA.event({
      category: 'User',
      action: 'Print Program'
    });
    setExpandAllProgramDetails(true)
    setExpandAllAdmissions(true)
    setExpandAllCost(true)
    setExpandAllServices(true)
    setExpandAllProfile(true)
    setExpandAllEmployment(true)
    setExpandAllAdditionalInfo(true)
    setExpandAllContactInfo(true)
    setTimeout(() => {window.print()}, 2000)
  }

  const attribute = (attr) => {
    if((typeof attr === 'string') && attr.match(/\|/)) {
      return (
        <ul>
          {attr.split(' | ').map((text) =>
              <li>{text}</li>
            )}
        </ul>
      )
    } else {
      return (
        <span>{attr}</span>
      )
    }
  }

  const stringToCurrency = (val) => {
    if(val === -1 || val === null || val === undefined) {
      return 'N/A'
    } else if (typeof val === 'string') {
      if (val.match(/[\d*]/) && val[0] !== '$') {
        return `$${val}`
      } else {
        return val
      }
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(String(val).replace(/,/, ''))
    }
  }

  const programsLink = () => {
    let queries = filterContext.currentQueryParams()

    if (queries && queries.length > 0) {
      return `/?${queries}`
    } else {
      return '/'
    }
  }

  const percentageValue = (val) => {
    if (val && String(val).match(/^[\d*\.?]*$/)) {
      return `${val}%`
    } else {
      return val
    }
  }

  const truncatedPercentageValue = (val) => {
    if(val < 0) {
     return 'Not available';
    } else {
     return `${Math.round(parseFloat(val*100))}%`;
    }
  }

  const studentProfile = (({ total, male, female, baas, latinx, asian, white, other }) => {
    return (
      <Fragment>
        {program.RecordType.Name !== 'Apprenticeship' && (
          <>
            {attributeExists(total) && <li><strong>Program Graduates 2022:&nbsp;</strong>{total === undefined ? 'Not Available' : total}</li>}
          </>
        )}
        {attributeExists(male) && <li><strong>Male Students:&nbsp;</strong>{male}</li>}
        {attributeExists(female) && <li><strong>Female Students:&nbsp;</strong>{female}</li>}
        {attributeExists(baas) && <li><strong>Black/African American Students:&nbsp;</strong>{baas}</li>}
        {attributeExists(latinx) && <li><strong>Latinx/Hispanic Students:&nbsp;</strong>{latinx}</li>}
        {attributeExists(asian) && <li><strong>Asian Students:&nbsp;</strong>{asian}</li>}
        {attributeExists(white) && <li><strong>White Students:&nbsp;</strong>{white}</li>}
        {attributeExists(other) && <li><strong>Other Race/Ethnicity Students:&nbsp;</strong>{other}</li>}
      </Fragment>
    )
  });

  const renderStudentProfile = () => {
    if(iped !== undefined && iped !== null) {
      return studentProfile({
        total: iped.ctotal,
        male: (iped.cmale && truncatedPercentageValue(iped.cmale)),
        female: (iped.cfemale && truncatedPercentageValue(iped.cfemale)),
        baas: (iped.cafricanamerican && truncatedPercentageValue(iped.cafricanamerican)),
        latinx: (iped.clatinx && truncatedPercentageValue(iped.clatinx)),
        asian: (iped.casian && truncatedPercentageValue(iped.casian)),
        white: (iped.cwhite && truncatedPercentageValue(iped.cwhite)),
        other: (iped.cotherrace && truncatedPercentageValue(iped.cotherrace))
      })
    } else {
      return studentProfile({
        total: undefined,
        male: (program.Male__c && percentageValue(program.Male__c)),
        female: (program.Female__c && percentageValue(program.Female__c)),
        baas: (program.Black_African_American__c && percentageValue(program.Black_African_American__c)),
        latinx: (program.Latinx_Hispanic_Spanish_Origin__c && percentageValue(program.Latinx_Hispanic_Spanish_Origin__c)),
        asian: (program.Asian__c && percentageValue(program.Asian__c)),
        white: (program.White__c && percentageValue(program.White__c)),
        other: (program.Other_Race_Ethnicity__c && percentageValue(program.Other_Race_Ethnicity__c))
      })
    }
  };

  const attributeExists = (attr) => {
    if(!attr) return false;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('showAll') && urlParams.get('showAll').toString().toLowerCase() === 'true') return true;

    return attr.toString().trim().toLowerCase() !== 'not provided' && attr.toString().trim().toLowerCase() !== 'n/a';
  };

  return (
    <Container maxWidth="md">
      <AuthenticatedNav />
      <main className={`${classes.program} program-main-content`}>
        { !program && (
          <div className={classes.loadingSpinner} >
            <CircularProgress/>
          </div>
        )}
        { program &&
          <div>
            <Breadcrumbs className={`${classes.breadcrumbs} program-breadcrumbs`} separator="›" aria-label="breadcrumb">
              <Link to={programsLink()} component={RouterLink}>
                <Typography component="div">
                  Programs
                </Typography>
              </Link>
              <Typography color="textPrimary">{program.Name}</Typography>
            </Breadcrumbs>
            <Paper className={classes.root}>
              <Grid sm={12}>
                <Button className={`${classes.button} print-program`} variant="contained" color="primary" onClick={handlePrint}>Print</Button>
              </Grid>

              <Typography variant="h3" component="h3">
                {program.Name}
              </Typography>
              <Typography component="div">
                <div className={classes.tileItem}>
                  {program.Specialty_Degree_Name__c && program.Degree_Level_Credential_Offered__c && (
                    <Fragment>
                      <School className={classes.svgIcon} />
                      <span className={classes.spanText}>
                        {program.Degree_Level_Credential_Offered__c}, {program.Specialty_Degree_Name__c}
                      </span>
                    </Fragment>
                  )}

                  {attributeExists(program.Career_Area__c) && (
                    <div>
                      <Briefcase className={classes.svgIcon} />
                      <span className={classes.spanText}>
                        {program.Career_Area__c}
                      </span>
                    </div>
                  )}

                  {attributeExists(program.Organization_Type__c) && attributeExists(program.School_Organization_Name__r) && (
                    <div>
                      <Domain className={classes.svgIcon} />
                      <span className={classes.spanText}>
                        {program.School_Organization_Name__r.Name} <span style={{color: '#a29f9f'}}>({program.Organization_Type__c})</span>
                      </span>
                    </div>
                  )}
                  {attributeExists(program.Regions__c) && (
                    <div>
                      <MapMarker className={classes.svgIcon} />
                      <span  className={classes.spanText}>
                        {program.Regions__c}
                      </span>
                    </div>
                  )}
                  {attributeExists(program.Program_URL__c) && (
                    <div>
                      <OpenInNew className={classes.svgIcon} />
                      <span className={classes.spanText}>
                        <a href={program.Program_URL__c} target="_blank">Program Website</a>
                      </span>
                    </div>
                  )}

                  {attributeExists(program.Program_Length__c) && (
                    <div>
                      <CalendarMonthOutline className={classes.svgIcon} />
                      <span className={classes.spanText}>
                        {program.Program_Length__c}
                      </span>
                    </div>
                  )}
                  {attributeExists(program.Acceptance_Rate__c) && (
                    <div>
                      <Typography variant='p' component='p'>
                        Acceptance Rate:
                        <span className={classes.summaryPercentageSpan}>
                          {percentageValue(program.Acceptance_Rate__c)}
                        </span>
                      </Typography>
                    </div>
                  )}
                  {attributeExists(program.Program_Graduation_Rate__c) && (
                    <div>
                      <Typography variant='p' component='p'>
                        Graduation Rate:
                        <span className={classes.summaryPercentageSpan}>
                          {percentageValue(program.Program_Graduation_Rate__c)}
                        </span>
                      </Typography>
                    </div>
                  )}
                </div>
              </Typography>

              {attributeExists(program.Program_Summary__c) && (
                <>
                  <Typography variant='h5' component='h5'>Program Summary</Typography>
                  <Typography component="p" className={classes.topSection}>
                    {program.Program_Summary__c}
                  </Typography>
                </>
              )}

              <Grid sm={12}>
                <Button className={`${classes.button} expand-all`} variant="contained" onClick={handleExpandAll}>Expand All</Button>
              </Grid>

              <div className={classes.expansionPanelsContainer}>
                <ExpansionPanel expanded={expandAllProgramDetails}
                                className="program-attributes-container"
                                TransitionProps={{
                                  timeout: 0
                                }}>
                  <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => setExpandAllProgramDetails(!expandAllProgramDetails)}
                  >
                    <Typography className={classes.heading}>Program Details</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <ul className={classes.attributeList}>
                        {attributeExists(program.Program_Length__c) && <li><strong>Program Length:&nbsp;</strong>{attribute(program.Program_Length__c)}</li>}
                        {attributeExists(program.Program_Length_Details__c) && <li><strong>Program Length Details:&nbsp;</strong>{attribute(program.Program_Length_Details__c)}</li>}
                        {attributeExists(program.Nearby_Public_Transportation__c) && <li><strong>Nearby Public Transportation:&nbsp;</strong>{attribute(program.Nearby_Public_Transportation__c)}</li>}
                        {attributeExists(program.Nearby_Public_Transportation_Details__c) && <li><strong>Nearby Public Transportation Details:&nbsp;</strong>{attribute(program.Nearby_Public_Transportation_Details__c)}</li>}
                        {attributeExists(program.Dorms_Housing_Offered__c) && <li><strong>Dorms/Housing Offered:&nbsp;</strong>{attribute(program.Dorms_Housing_Offered__c)}</li>}
                        {attributeExists(program.Dorms_Housing_Offered_Details__c) && <li><strong>Dorms/Housing Offered Details:&nbsp;</strong>{attribute(program.Dorms_Housing_Offered_Details__c)}</li>}
                        {program.RecordType.Name === 'Apprenticeship' && (
                          <Fragment>
                          {attributeExists(program.Apprenticeship_Structure__c) && <li><strong>Apprenticeship Structure:&nbsp;</strong>{attribute(program.Apprenticeship_Structure__c)}</li>}
                          {attributeExists(program.Apprenticeship_Performance_Standards__c) && <li><strong>Apprenticeship Performance Standards:&nbsp;</strong>{attribute(program.Apprenticeship_Performance_Standards__c)}</li>}
                          {attributeExists(program.Reasons_for_Incompletion__c) && <li><strong>Why Some Don’t Complete the Program:&nbsp;</strong>{attribute(program.Reasons_for_Incompletion__c)}</li>}
                          {attributeExists(program.Pay_During_Apprenticeship__c) && <li><strong>Pay During Apprenticeship:&nbsp;</strong>{attribute(program.Pay_During_Apprenticeship__c)}</li>}
                          {attributeExists(program.Work_Benefits__c) && <li><strong>Work Benefits:&nbsp;</strong>{attribute(program.Work_Benefits__c)}</li>}
                          {attributeExists(program.Work_Benefits_Details__c) && <li><strong>Work Benefits Details:&nbsp;</strong>{attribute(program.Work_Benefits_Details__c)}</li>}
                          {attributeExists(program.College_Credit_Postsecondary_Partner__c) && <li><strong>College Credit Postsecondary Partner:&nbsp;</strong>{attribute(program.College_Credit_Postsecondary_Partner__c)}</li>}
                          {attributeExists(program.College_Credit_Partner_Details__c) && <li><strong>College Credit Partner Details:&nbsp;</strong>{attribute(program.College_Credit_Partner_Details__c)}</li>}
                          </Fragment>
                        )}
                      </ul>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandAllAdmissions}
                                className="program-attributes-container"
                                TransitionProps={{
                                  timeout: 0
                                }}>
                  <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    onClick={() => setExpandAllAdmissions(!expandAllAdmissions)}
                  >
                    <Typography className={classes.heading}>Admissions</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <ul className={classes.attributeList}>
                        {attributeExists(program.When_You_Can_First_Apply_to_the_Program__c) && <li><strong>When You Can First Apply:&nbsp;</strong>{attribute(program.When_You_Can_First_Apply_to_the_Program__c)}</li>}
                        {attributeExists(program.Program_Admissions_Process__c) && <li><strong>Program Admissions Process:&nbsp;</strong>{attribute(program.Program_Admissions_Process__c)}</li>}
                        {attributeExists(program.Suggestions_for_Applicants__c) && <li><strong>Suggestions for Applicants:&nbsp;</strong>{attribute(program.Suggestions_for_Applicants__c)}</li>}

                        {attributeExists(program.Acceptance_Rate__c) && <li><strong>Acceptance Rate:&nbsp;</strong>{percentageValue(program.Acceptance_Rate__c)}</li>}
                        {attributeExists(program.Number_of_Applicants__c) && <li><strong>Number of Applicants:&nbsp;</strong>{attribute(program.Number_of_Applicants__c)}</li>}
                        {attributeExists(program.Accepted_Applicants__c) && <li><strong>Number of Accepted Applicants:&nbsp;</strong>{attribute(program.Accepted_Applicants__c)}</li>}
                        {attributeExists(program.Profile_of_Accepted_Students__c) && <li><strong>Profile of Accepted Students:&nbsp;</strong>{attribute(program.Profile_of_Accepted_Students__c)}</li>}
                        {program.RecordType.Name === 'Apprenticeship' || program.RecordType.Name !== 'SubBA' && (
                          <Fragment>
                          {attributeExists(program.Within_Same_College_Transfer_Option__c) && <li><strong>Within Same College Transfer Option:&nbsp;</strong>{attribute(program.Within_Same_College_Transfer_Option__c)}</li>}
                          {attributeExists(program.Transfer_Requirements__c) && <li><strong>Transfer Requirements:&nbsp;</strong>{attribute(program.Transfer_Requirements__c)}</li>}
                          {attributeExists(program.Number_of_Transfers_Typically_Accepted__c) && <li><strong>Number of Transfers Typically Accepted:&nbsp;</strong>{attribute(program.Number_of_Transfers_Typically_Accepted__c)}</li>}
                          </Fragment>
                        )}
                          {attributeExists(program.Program_Waitlist__c) && <li><strong>Program Waitlist:&nbsp;</strong>{attribute(program.Program_Waitlist__c)}</li>}
                          {attributeExists(program.Program_Waitlist_Details__c) && <li><strong>Program Waitlist Details:&nbsp;</strong>{attribute(program.Program_Waitlist_Details__c)}</li>}
                      </ul>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandAllCost}
                                className="program-attributes-container"
                                TransitionProps={{
                                  timeout: 0
                                }}>
                  <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    onClick={() => setExpandAllCost(!expandAllCost)}
                  >
                    <Typography className={classes.heading}>Program Cost and Financial Aid</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <ul className={classes.attributeList}>
                        {attributeExists(program.cost?.avg_net_price) && <li><strong>Net Annual Cost (i.e., Cost Minus Scholarship Aid):&nbsp;</strong>{stringToCurrency(program.cost.avg_net_price)}</li>}
                        {attributeExists(program.cost?.net_price_0_48k) && <li><strong>Net Annual Cost for Students with Family Income below $48,000:&nbsp;</strong>{stringToCurrency(program.cost.net_price_0_48k)}</li>}
                        {attributeExists(program.Program_Cost_Details__c) && <li><strong>Program Cost Details:&nbsp;</strong>{attribute(program.Program_Cost_Details__c)}</li>}
                        {program.RecordType.Name !== 'Apprenticeship' && (
                          <Fragment>
                            {attributeExists(program.Financial_Aid_Programs__c) && <li><strong>Financial Aid Programs:&nbsp;</strong>{attribute(program.Financial_Aid_Programs__c)}</li>}
                            {attributeExists(program.Scholarship_Programs__c) && <li><strong>Scholarship Programs:&nbsp;</strong>{attribute(program.Scholarship_Programs__c)}</li>}
                            {attributeExists(program.aid?.median) && <li><strong>Median Loan Debt of Graduates:&nbsp;</strong>{attribute(stringToCurrency(program.aid.median))}</li>}
                            {attributeExists(program.aid?.low_income_grad_debt) && <li><strong>Median Loan Debt of Students with Family Income below $30,000:&nbsp;</strong>{attribute(stringToCurrency(program.aid.low_income_grad_debt))}</li>}
                          </Fragment>
                        )}
                      </ul>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandAllServices}
                                className="program-attributes-container"
                                TransitionProps={{
                                  timeout: 0
                                }}>
                  <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    onClick={() => setExpandAllServices(!expandAllServices)}
                  >
                    <Typography className={classes.heading}>Support Services and Diversity Efforts</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <ul className={classes.attributeList}>
                        {attributeExists(program.Support_Services__c) && <li><strong>Support Services:&nbsp;</strong>{attribute(program.Support_Services__c)}</li>}
                        {attributeExists(program.Support_Services_Details__c) && <li><strong>Support Services Details:&nbsp;</strong>{attribute(program.Support_Services_Details__c)}</li>}
                        {attributeExists(program.Program_Diversity_Efforts__c) && <li><strong>Program Diversity Efforts:&nbsp;</strong>{attribute(program.Program_Diversity_Efforts__c)}</li>}
                        {attributeExists(program.Program_Diversity_Efforts_Details__c) && <li><strong>Program Diversity Efforts Details:&nbsp;</strong>{attribute(program.Program_Diversity_Efforts_Details__c)}</li>}
                        {program.RecordType.Name !== 'Apprenticeship' && (
                          <>
                            {attributeExists(program.Career_Services__c) && <li><strong>Career Services:&nbsp;</strong>{attribute(program.Career_Services__c)}</li>}
                          </>
                        )}
                      </ul>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandAllProfile}
                                className="program-attributes-container"
                                TransitionProps={{
                                  timeout: 0
                                }}>
                  <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    onClick={() => setExpandAllProfile(!expandAllProfile)}
                  >
                    <Typography className={classes.heading}>Student Profile and Outcomes</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <ul className={classes.attributeList}>
                        { renderStudentProfile() }
                        {attributeExists(program.Keys_to_Success__c) && <li><strong>Keys to Success:&nbsp;</strong>{attribute(program.Keys_to_Success__c)}</li>}

                        {program.RecordType.Name !== 'Apprenticeship' && (
                          <Fragment>
                            {attributeExists(program.Program_Retention_Rate__c) && <li><strong>Program Retention Rate:&nbsp;</strong>{percentageValue(program.Program_Retention_Rate__c)}</li>}
                            {attributeExists(program.Program_Graduation_Rate__c) && <li><strong>Program Graduation Rate:&nbsp;</strong>{percentageValue(program.Program_Graduation_Rate__c)}</li>}
                            {attributeExists(program.Pass_First_Try__c) && <li><strong>First Try Pass Rate on Certification/Licensure Exam:&nbsp;</strong>{percentageValue(program.Pass_First_Try__c)}</li>}
                          </Fragment>
                        )}
                        {program.RecordType.Name === 'Apprenticeship' && (
                          <Fragment>
                            {attributeExists(program.Program_Graduation_Rate__c) && <li><strong>Program Graduation Rate:&nbsp;</strong>{percentageValue(program.Program_Graduation_Rate__c)}</li>}
                          </Fragment>
                        )}
                      </ul>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandAllEmployment}
                                className="program-attributes-container"
                                TransitionProps={{
                                  timeout: 0
                                }}>
                  <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    onClick={() => setExpandAllEmployment(!expandAllEmployment)}
                  >
                    <Typography className={classes.heading}>Employment and Transfer Outcomes</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <ul className={classes.attributeList}>
                        {attributeExists(program.Target_Occupations__c) && <li><strong>Target Occupations:&nbsp;</strong>{attribute(program.Target_Occupations__c)}</li>}
                        {attributeExists(program.Post_program_Jobs__c) && <li><strong>Job Titles of Recent Program Graduates:&nbsp;</strong>{attribute(program.Post_program_Jobs__c)}</li>}
                        {attributeExists(program.Employment_Rate_in_Related_Occupation__c) && <li><strong>Employment Rate in Target Occupations:&nbsp;</strong>{percentageValue(program.Employment_Rate_in_Related_Occupation__c)}</li>}
                        {attributeExists(program.Hiring_Organizations__c) && <li><strong>Employers of Recent Program Graduates:&nbsp;</strong>{attribute(program.Hiring_Organizations__c)}</li>}
                        {attributeExists(program.Starting_Pay_of_Graduates__c) && <li><strong>Graduate Earnings:&nbsp;</strong>{stringToCurrency(program.Starting_Pay_of_Graduates__c)}</li>}
                        <li><strong>Average Boston Area Salaries for Occupations Targeted by Program:&nbsp;</strong>
                          <div className={classes.salaries}>
                            {program.salaries ?
                              <Salaries
                                twentyFifth={program.salaries.pct25}
                                median={program.salaries.median}
                                seventyFifth={program.salaries.pct75}
                              />
                              :
                              <Salaries
                                twentyFifth={stringToCurrency(program.Twenty_fifth_percentile_Salary__c)}
                                median={stringToCurrency(program.Median_Salary_for_Occupations_Linked_to__c)}
                                seventyFifth={stringToCurrency(program.Seventy_fifth_percentile_Salary__c)}
                              />
                            }
                          </div>
                        </li>
                        {attributeExists(program.Higher_Education_Rate__c) && <li><strong>Higher Education Rate:&nbsp;</strong>{percentageValue(program.Higher_Education_Rate__c)}</li>}
                        {attributeExists(program.Higher_Education_Field_of_Study__c) && <li><strong>Higher Education Field of Study:&nbsp;</strong>{attribute(program.Higher_Education_Field_of_Study__c)}</li>}
                      </ul>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandAllAdditionalInfo}
                                className="program-attributes-container"
                                TransitionProps={{
                                  timeout: 0
                                }}>
                  <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    onClick={() => setExpandAllAdditionalInfo(!expandAllAdditionalInfo)}
                  >
                    <Typography className={classes.heading}>Additional Information</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <ul className={classes.attributeList}>
                        {attributeExists(program.Additional_Information__c) && <li><strong>Additional Information:&nbsp;</strong>
                          {attribute(program.Additional_Information__c)}
                        </li>}
                      </ul>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expandAllContactInfo}
                                className="program-attributes-container"
                                TransitionProps={{
                                  timeout: 0
                                }}>
                  <ExpansionPanelSummary
                    className={classes.expansionPanelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    onClick={() => setExpandAllContactInfo(!expandAllContactInfo)}
                  >
                    <Typography className={classes.heading}>Contact Information</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <ul className={classes.attributeList}>
                        {(attributeExists(program.Contact__r?.FirstName) || attributeExists(program.Contact__r?.LastName)) && <li><strong>Name:&nbsp;</strong>{program.Contact__r.FirstName} {program.Contact__r && program.Contact__r.LastName}</li>}
                        {attributeExists(program.Contact__r?.Title) && <li><strong>Title:&nbsp;</strong>{attribute(program.Contact__r.Title)}</li>}
                        {attributeExists(program.Contact__r?.Email) && <li><strong>Email:&nbsp;</strong>{attribute(program.Contact__r.Email)}</li>}
                        {attributeExists(program.Contact__r?.Phone) && <li><strong>Phone Number:&nbsp;</strong>{attribute(program.Contact__r.Phone)}</li>}
                        {attributeExists(program.Contact__r?.MailingCity) && <li><strong>City:&nbsp;</strong>{attribute(program.Contact__r.MailingCity)}</li>}
                        {attributeExists(program.Contact__r?.MailingState) && <li><strong>State:&nbsp;</strong>{attribute(program.Contact__r.MailingState)}</li>}
                      </ul>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </Paper>
          </div>
        }
      </main>
    </Container>

  )
}

export default ProgramContainer
